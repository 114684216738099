import { LOCALE_CODES } from '@yescapa-dev/ysc-components/i18n' // TODO migrate module
import type { AvailableLocaleCodes } from './types'

/*
  Ensure the first letter is uppercase
  @param yscCode: we should not lowercase german words
*/
export function upperCaseFirstLetter(str: string, yscCode: AvailableLocaleCodes | null = null) {
  const shouldLowerCase = !yscCode ? true : getTopLevelLocale(yscCode) !== LOCALE_CODES.DE
  return `${str.slice(0, 1).toUpperCase()}${shouldLowerCase ? str.slice(1).toLowerCase() : str.slice(1)}`
}
